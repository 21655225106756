<template>
    <sort-list
        v-model="list"
        @init="init"
        :defaultQuery="defaultQuery"
        ref="list"
        url="/policyLaw/all"
        :filters="filters"
        :sortKey="sortKey"
    >
        <template v-for="item in list">
            <law-row :key="item.id" :info="item"></law-row>
        </template>
    </sort-list>
</template>

<script>
import LawRow from '../../components/list/LawRow.vue';
import SortList from '../../components/page/SortList.vue';
export default {
    components: { SortList, LawRow },
    created() {
        this.init();
    },
    data() {
        return {
            typeSettingIdOptions: [],
            list: [],
            defaultQuery: {
                category: 'LAW'
            }
        };
    },
    computed: {
        filters() {
            return [
                {
                    name: '法律类型',
                    key: 'typeSettingId',
                    type: 'select',
                    list: [...this.typeSettingIdOptions],
                    default: this.$route.query.typeSettingId ? Number(this.$route.query.typeSettingId) : ''
                },
                {
                    name: '法律名称',
                    key: 'name'
                },
                {
                    name: '发布年份',
                    key: 'issuedAt',
                    type: 'date'
                },
                {
                    name: '实施年份',
                    key: 'implementationAt',
                    type: 'date'
                }
            ];
        },
        sortKey() {
            return [
                {
                    name: '发布时间',
                    key: 'issuedAt'
                }
            ];
        }
    },
    methods: {
        init() {
            this.$http
                .post('/setting/byFlag', { flag: 9 })
                .then(res => {
                    if (res.length > 0) {
                        this.typeSettingIdOptions = res.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            };
                        });
                    }

                    this.$nextTick(() => {
                        this.$refs.list.init();
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    }
};
</script>

<style lang="less" scoped></style>
