<template>
    <router-link
        :to="{
            name: 'legalDetail',
            query: {
                id: info.id
            }
        }"
        class="patent"
    >
        <div class="name">
            {{ info.name }}
        </div>
        <div class="used"></div>
        <div class="time">{{ formatTime(info.issuedAt, 'YYYY.MM.DD') }}</div>
    </router-link>
</template>

<script>
import comEvent from '../../mixins/comEvent';
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    mixins: [comEvent]
};
</script>

<style lang="less" scoped>
.patent {
    padding: 10px 20px;
    .flex();

    position: relative;
    cursor: pointer;

    .name {
        font-size: 14px;
        color: #000000;
        line-height: 24px;
        padding: 0 10px 0 5px;
        .ellipsis();
    }

    .used {
        font-size: 14px;
        color: #000000;
        line-height: 24px;
        flex-grow: 1;
        padding-right: 250px;
        flex-shrink: 0;
    }

    .time {
        font-size: 14px;
        color: #939599;
        line-height: 24px;
        flex-shrink: 0;
    }

    &::before {
        content: '';
        width: 4px;
        height: 4px;
        background: #e3e4e6;
        flex-shrink: 0;
    }

    &::after {
        content: '';
        left: 20px;
        right: 20px;
        position: absolute;
        bottom: 0;
        height: 1px;
        background-color: @bg;
    }

    &:hover {
        .name {
            color: @prim;
        }
        .used {
            color: @prim;
        }
        .time {
            color: @prim;
        }
        &::before {
            background-color: @prim;
        }
    }
    overflow: hidden;
}
</style>
