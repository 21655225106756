import { render, staticRenderFns } from "./LawRow.vue?vue&type=template&id=1960d2f0&scoped=true"
import script from "./LawRow.vue?vue&type=script&lang=js"
export * from "./LawRow.vue?vue&type=script&lang=js"
import style0 from "./LawRow.vue?vue&type=style&index=0&id=1960d2f0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1960d2f0",
  null
  
)

export default component.exports